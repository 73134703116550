@use "@general/styles/bootstrap/boostrap_configuration" as bst;

.contact-form {
    background-color: bst.$white;
    border-radius: bst.$border-radius;
    .btn-lg{
      width: 300px;
      max-width: 100%;
    }
    .message-space{
      min-height: 40px;
    }
}